
//import JnPubblicazioniSearch from "@/views/search/PubblicazioniSearch.vue";
import JnCardsStrip from "@/views/common/CardsStrip.vue";
import JnSearchResults from "@/views/search/SearchResults.vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import { SearchData } from "@/models/search.model";
import { Testata } from '@/models/testata.model';
import MetaInfo from "vue-meta";
import {
  Sections,
  RoutesToCategories,
  RoutesToNames,
  Pubblicazioni,
} from "@/models/enums.model";
import { EsService } from "@/services/es.service";
import JnMainNewsGroup from "@/views/common/MainNewsGroup.vue";
import EventBus from "@/services/event.bus";
import { Pagination } from "@/models/pagination.model";
import { Document } from "@/models/document.model";
import JnDoc from "@/views/common/DocCard.vue";

@Component({
  components: {
    //JnPubblicazioniSearch,
    JnCardsStrip,
    JnSearchResults,
    JnMainNewsGroup,
    JnDoc
  },
  metaInfo(this: JnPubblicazioni): MetaInfo {
    return {
      title: "JuraNews - Pubblicazioni",
    };
  },
})
export default class JnPubblicazioni extends Vue {

  testata?: Testata;

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    keywords: [],
    perPage: 20,
    from: 0,
    searchScope: "pubblicazioni",
  });

  rivistePagination?: Pagination;
  rivisteAllDocs: Document[] = [];
  rivisteShownDocs: Document[] = [];

  guideOperativePagination?: Pagination;
  guideOperativeAllDocs: Document[] = [];
  guideOperativeShownDocs: Document[] = [];

  aigaNewsPagination?: Pagination;
  aigaNewsAllDocs: Document[] = [];
  aigaNewsShownDocs: Document[] = [];

  rnfMagazinePagination?: Pagination;
  rnfMagazineAllDocs: Document[] = [];
  rnfMagazineShownDocs: Document[] = [];

  rivisteSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Pubblicazioni.RIVISTE],
    withText: true,
  });
  guideOperativeSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Pubblicazioni.GUIDE_OPERATIVE],
    withText: true,
  });
  aigaNewsSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Pubblicazioni.AIGA_NEWS],
    withText: true,
  });
  rnfMagazineSearchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    idCategory: [Pubblicazioni.RNF_MAGAZINE],
    withText: true,
  });

  async beforeMount() {
    this.testata = JnService.getTestata(Sections.PUBBLICAZIONI);

    this.rivisteAllDocs = await EsService.getDocs(this.rivisteSearchData);

    this.rivistePagination = new Pagination({
      page: 1,
      total: this.rivisteAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showRiviste(0, this.rivistePagination.perPage);

    this.guideOperativeAllDocs = await EsService.getDocs(
      this.guideOperativeSearchData
    );
    this.guideOperativePagination = new Pagination({
      page: 1,
      total: this.guideOperativeAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showGuideOperative(0, this.guideOperativePagination.perPage);


    this.aigaNewsAllDocs = await EsService.getDocs(
      this.aigaNewsSearchData
    );
    this.aigaNewsPagination = new Pagination({
      page: 1,
      total: this.aigaNewsAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showAigaNews(0, this.aigaNewsPagination.perPage);

    this.rnfMagazineAllDocs = await EsService.getDocs(
      this.rnfMagazineSearchData
    );
    this.rnfMagazinePagination = new Pagination({
      page: 1,
      total: this.rnfMagazineAllDocs.length,
      perPage: 4,
      visible: 6,
    });
    this.showRnfMagazine(0, this.rnfMagazinePagination.perPage);

  }

  updated() {
    EventBus.$emit("search-results", []);
  }

  // Riviste
  nextRiviste() {
    if (this.rivistePagination) {
      const from =
        (this.rivistePagination.page - 1) * this.rivistePagination.perPage;
      const size = this.rivistePagination.perPage;
      this.showRiviste(from, size);
    }
  }
  showRiviste(from: number, size: number) {
    this.rivisteShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.rivisteAllDocs[i]) {
        this.rivisteShownDocs.push(this.rivisteAllDocs[i]);
      }
    }
  }


  // Guide Operative
  nextGuideOperative() {
    if (this.guideOperativePagination) {
      const from =
        (this.guideOperativePagination.page - 1) *
        this.guideOperativePagination.perPage;
      const size = this.guideOperativePagination.perPage;
      this.showGuideOperative(from, size);
    }
  }
  showGuideOperative(from: number, size: number) {
    this.guideOperativeShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.guideOperativeAllDocs[i]) {
        this.guideOperativeShownDocs.push(this.guideOperativeAllDocs[i]);
      }
    }
  }

  // AIGA News
  nextAigaNews() {
    if (this.aigaNewsPagination) {
      const from =
        (this.aigaNewsPagination.page - 1) *
        this.aigaNewsPagination.perPage;
      const size = this.aigaNewsPagination.perPage;
      this.showAigaNews(from, size);
    }
  }
  showAigaNews(from: number, size: number) {
    this.aigaNewsShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.aigaNewsAllDocs[i]) {
        this.aigaNewsShownDocs.push(this.aigaNewsAllDocs[i]);
      }
    }
  }

  // RNF Magazine
  nextRnfMagazine() {
    if (this.rnfMagazinePagination) {
      const from =
        (this.rnfMagazinePagination.page - 1) *
        this.rnfMagazinePagination.perPage;
      const size = this.rnfMagazinePagination.perPage;
      this.showRnfMagazine(from, size);
    }
  }
  showRnfMagazine(from: number, size: number) {
    this.rnfMagazineShownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.rnfMagazineAllDocs[i]) {
        this.rnfMagazineShownDocs.push(this.rnfMagazineAllDocs[i]);
      }
    }
  }
}
